import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock5White = (key) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Muenchen-LMU/Schaubild-Bayerischeverfassung-1946.png"}
                           data-glightbox="title: Schaubild: Die Bayerische Verfassung von 1946; description: .custom-desc1_12; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Muenchen-LMU/Schaubild-Bayerischeverfassung-1946.png"  width={335} layout="fixed" placeholder="none"  alt="Die Bayerische Verfassung von 1946"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_12">
                            <p>

                            </p>
                        </div>
                        <figcaption>
                            Die Bayerische Verfassung von 1946
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="center">
                        <StaticImage src="../../../../static/images/Muenchen-LMU/silhouette-stimmzettel.png" width={335} layout="fixed" placeholder="none"  alt=""/>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock5White
