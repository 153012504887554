import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock4Parallax = (key, ortName) => {
    return (
        <Container.Part background="bg-img parallax-bg parallax-muenchen-lmu" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Muenchen-LMU/lmu_bild_0011.jpg"}
                           data-glightbox="title: Flugblatt zum Volksbegehren „Eine Schule für alle“, 1967; description: .custom-desc1_7; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0011.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Flugblatt zum Volksbegehren „Eine Schule für alle“, 1967"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_7">
                            <p>
                                Zu einer ersten Änderung der Verfassung kam es 1968, mehr als 20 Jahre, nachdem sie in
                                Kraft getreten war. Ein Volksentscheid nahm die Einführung der christlichen
                                Gemeinschaftsschule als Regelschule in Bayern an. CSU, SPD und FDP hatten sich auf einen
                                gemeinsamen Entwurf verständigt.
                            </p>
                            <p className="copy">Abb. HSS-ACSP, Fl 1967</p>
                        </div>
                        <figcaption>
                            Flugblatt zum Volksbegehren „Eine Schule für alle“, 1967
                            <span className="description">
                                Zu einer ersten Änderung der Verfassung kam es 1968, mehr als 20 Jahre, nachdem sie in
                                Kraft getreten war. Ein Volksentscheid nahm die Einführung der christlichen
                                Gemeinschaftsschule als Regelschule in Bayern an. CSU, SPD und FDP hatten sich auf einen
                                gemeinsamen Entwurf verständigt.</span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Muenchen-LMU/lmu_bild_0012.jpg"}
                           data-glightbox="title: Wahlplakat zum Volksbegehren „Mehr Demokratie in Bayern: Bürgerentscheide in Gemeinden und Kreisen“, 1995; description: .custom-desc1_8; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0012.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Wahlplakat zum Volksbegehren „Mehr Demokratie in Bayern: Bürgerentscheide in Gemeinden und Kreisen“, 1995"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_8">
                            <p>
                                Mitte der 1990er-Jahre kam es verstärkt zu Diskussionen um Reformen der Verfassung. Der
                                Verein „Mehr Demokratie in Bayern“ setzte mit einem Volksentscheid 1995 die Einführung
                                von Bürgerbegehren und Bürgerentscheiden auf kommunaler Ebene durch.
                            </p>
                            <p className="copy">Abb. Bayerisches Hauptstaatsarchiv, Plakatsammlung Nr. 38389</p>
                        </div>
                        <figcaption>
                            Wahlplakat zum Volksbegehren „Mehr Demokratie in Bayern: Bürgerentscheide in Gemeinden und
                            Kreisen“, 1995
                            <span className="description">
                                Mitte der 1990er-Jahre kam es verstärkt zu Diskussionen um Reformen der Verfassung. Der
                                Verein „Mehr Demokratie in Bayern“ setzte mit einem Volksentscheid 1995 die Einführung
                                von Bürgerbegehren und Bürgerentscheiden auf kommunaler Ebene durch.</span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
            <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Muenchen-LMU/lmu_bild_0014.jpg"}
                               data-glightbox="title: Plakat der CSU für Volksentscheide, 1998; description: .custom-desc1_9; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0014.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Plakat der CSU für Volksentscheide, 1998"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_9">
                                <p>
                                    1998 stimmten die bayerischen Wähler über drei Verfassungsänderungen ab, die die
                                    Grundrechte und Staatsziele, den Landtag und die Staatsregierung betrafen. Der
                                    Bayerische Senat wurde als zweite Kammer des Landtags abgeschafft. Bayern bekannte
                                    sich zu einem geeinten Europa. An der Abstimmung im Februar 1998 beteiligten sich
                                    39,9 Prozent der Stimmberechtigten. Dem Volksentscheid „Grundrechte und Staatsziele“
                                    stimmten 75,0 Prozent der Wähler zu, dem Volksentscheid „Landtag und
                                    Staatsregierung“ 73,9 Prozent.
                                </p>
                                <p className="copy">Abb. HSS-ACSP, PL S 4103</p>
                            </div>
                            <figcaption>
                                Plakat der CSU für Volksentscheide, 1998
                                <span className="description">
                                    1998 stimmten die bayerischen Wähler über drei Verfassungsänderungen ab, die die
                                    Grundrechte und Staatsziele, den Landtag und die Staatsregierung betrafen. Der
                                    Bayerische Senat wurde als zweite Kammer des Landtags abgeschafft. Bayern bekannte
                                    sich zu einem geeinten Europa. An der Abstimmung im Februar 1998 beteiligten sich
                                    39,9 Prozent der Stimmberechtigten. Dem Volksentscheid „Grundrechte und Staatsziele“
                                    stimmten 75,0 Prozent der Wähler zu, dem Volksentscheid „Landtag und
                                    Staatsregierung“ 73,9 Prozent.</span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Muenchen-LMU/lmu_bild_0013.jpg"}
                               data-glightbox="title: Plakat der SPD zum Volksentscheid Umweltschutz, 1984; description: .custom-desc1_11; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0013.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Plakat der SPD zum Volksentscheid Umweltschutz, 1984"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_11">
                                <p>
                                    In den 1980er-Jahren gab es in Bayern zahlreiche Konflikte um Natur- und
                                    Umweltschutz. Themen waren unter anderem das Waldsterben, die
                                    Wiederaufbereitungsanlage Wackersdorf und der Rhein-Main-Donau-Kanal. Die
                                    SPD-Landtagsfraktion ergriff 1984 die Initiative zu einer Verfassungsänderung, die
                                    dann im Konsens mit der CSU zum Volksentscheid vorgelegt wurde. 94 Prozent der
                                    Bürger stimmten für die Aufnahme des Umweltschutzes als Staatsziel in die Bayerische
                                    Verfassung.
                                </p>
                                <p className="copy">Abb. Archiv der sozialen Demokratie der Friedrich-Ebert-Stiftung,
                                    6/PLKA012381</p>
                            </div>
                            <figcaption>
                                Plakat der SPD zum Volksentscheid Umweltschutz, 1984
                                <span className="description">
                                    In den 1980er-Jahren gab es in Bayern zahlreiche Konflikte um Natur- und
                                    Umweltschutz. Themen waren unter anderem das Waldsterben, die
                                    Wiederaufbereitungsanlage Wackersdorf und der Rhein-Main-Donau-Kanal. Die
                                    SPD-Landtagsfraktion ergriff 1984 die Initiative zu einer Verfassungsänderung, die
                                    dann im Konsens mit der CSU zum Volksentscheid vorgelegt wurde. 94 Prozent der
                                    Bürger stimmten für die Aufnahme des Umweltschutzes als Staatsziel in die Bayerische
                                    Verfassung.</span>
                            </figcaption>
                        </figure>
                        <br/>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Muenchen-LMU/lmu_bild_0008.jpg"}
                               data-glightbox="title: Demonstration zum Volksbegehren „Schlanker Staat ohne Senat“, 1997; description: .custom-desc1_10; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0008.jpg" width={335} layout="fixed" placeholder="none"
                                             alt="Demonstration zum Volksbegehren „Schlanker Staat ohne Senat“, 1997"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_10">
                                <p>
                                    Kontrovers verlief die Diskussion um die Zukunft des Senats als eine zweite Kammer
                                    neben dem Landtag. Während der Landtag einen Entwurf zur Reform vorlegte, forderte
                                    ein von der ÖDP angeregtes und von SPD, FDP und den Grünen unterstütztes
                                    Volksbegehren dessen Abschaffung. Beim Volksentscheid stimmten 69,2 Prozent der
                                    Wähler für den Gesetzentwurf des Volksbegehrens. In der Folge wurde zum 1. Januar
                                    2000 der Senat aufgelöst.
                                </p>
                                <p className="copy">Abb. Karl-Heinz Egginger / Süddeutsche Zeitung Photo</p>
                            </div>
                            <figcaption>
                                Demonstration zum Volksbegehren „Schlanker Staat ohne Senat“, 1997
                                <span className="description">
                                    Kontrovers verlief die Diskussion um die Zukunft des Senats als eine zweite Kammer
                                    neben dem Landtag. Während der Landtag einen Entwurf zur Reform vorlegte, forderte
                                    ein von der ÖDP angeregtes und von SPD, FDP und den Grünen unterstütztes
                                    Volksbegehren dessen Abschaffung. Beim Volksentscheid stimmten 69,2 Prozent der
                                    Wähler für den Gesetzentwurf des Volksbegehrens. In der Folge wurde zum 1. Januar
                                    2000 der Senat aufgelöst.</span>
                            </figcaption>
                        </figure>
                        <br/>
                    </div>
                </Container.Row>
            </Container.More>
        </Container.Part>
    )
}

export default TextBlock4Parallax
