import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock1blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„…die Segnungen des Friedens,<br/><span>&nbsp;</span>der Menschlichkeit und des<br/>Rechtes
                        dauernd zu sichern…“ </h2>
                    <div className="inner-container">
                        <p>
                            Die Große Aula der Ludwig-Maximilians-Universität (LMU) ist ein zentraler Ort für die
                            Erneuerung von Staatlichkeit und Demokratie in Bayern nach dem Ende der NS-Herrschaft. Hier
                            wurde die bis heute gültige Bayerische Verfassung erarbeitet.
                        </p>
                        <p>
                            Mit dem Kriegsende im Mai 1945 übernahmen die vier Siegermächte vorläufig die
                            Regierungsgewalt. Die US-Militärregierung in Bayern bestellte zunächst den BVP-Politiker
                            Fritz Schäffer als Ministerpräsidenten in Bayern, dann den Sozialdemokraten Wilhelm Hoegner,
                            der schon im Schweizer Exil an Plänen zu einer neuen bayerischen Verfassung gearbeitet
                            hatte. Er bekam im Februar 1946 den Auftrag zur Vorbereitung einer Verfassunggebenden
                            Landesversammlung. Dazu wurde ein Vorbereitender Verfassungsausschuss konstituiert.
                        </p>

                        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                            <p>
                                Im Juli 1946 konnte die Verfassunggebende Landesversammlung in der Aula der LMU ihre
                                Arbeit aufnehmen. Es war die erste demokratisch gewählte Volksvertretung seit 1934. In
                                zehn öffentlichen Plenarsitzungen entschied sie über die zukünftige Verfassung. Heiß
                                diskutiert wurde die Einführung des Amtes eines bayerischen Staatspräsidenten und die
                                Schaffung einer zweiten Kammer. Die Verfassung wurde am 1. Dezember 1946 in einem
                                Volksentscheid mit einer Mehrheit von 70,6 Prozent angenommen.
                            </p>
                            <p>
                                Mit der Verfassung konstituierte sich Bayern als Volks-, Rechts-, Kultur- und
                                Sozialstaat, der dem Gemeinwohl dient. Ein starker Landtag als Herz der
                                parlamentarischen Demokratie, eine gestärkte Staatsspitze und -regierung sowie Elemente
                                unmittelbarer Demokratie kennzeichnen die Verfassung. Gleichzeitig mit der Abstimmung
                                über den Verfassungsentwurf wurde der erste Bayerische Landtag der Nachkriegszeit
                                gewählt. Dessen konstituierende Sitzung fand ebenfalls in der Großen Aula der LMU statt.
                            </p>
                        </Container.More>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1blue
