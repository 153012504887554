import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background="Muenchen-lmu" key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>
                                1946<br/>München<br/>Große Aula der<br/>Ludwig-Maximilians-Universität
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Verfassung</li>
                                <li>Parlamentarismus</li>
                                <li>Grund- und Menschenrechte</li>
                                <li>Parteien</li>
                                <li>Wahlen</li>
                            </ul>
                            <Container.If env="t">
                                <p>
                                    In der Großen Aula der LMU beriet ab Juli 1946 die Verfassunggebende
                                    Landesversammlung über die bis heute gültige Verfassung des Freistaats Bayern. Dort
                                    konstituierte sich dann auch der erste bayerische Nachkriegslandtag.
                                </p>
                            </Container.If>
                            <Container.If env="w">
                                <p>
                                    In der Großen Aula der LMU beriet ab Juli 1946 die Verfassunggebende
                                    Landesversammlung über die bis heute gültige Verfassung des Freistaats Bayern. Dort
                                    konstituierte sich dann auch der erste bayerische Nachkriegslandtag und wählte Hans
                                    Ehard zum Ministerpräsidenten.
                                </p>
                            </Container.If>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Muenchen-LMU/lmu_bild_0004.jpg"}
                                   data-glightbox="title: Zerstörter Eingangsbereich im Hauptgebäude der LMU, 1945; description: .custom-desc1_2; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0004.jpg"  width={335} layout="fixed" placeholder="none"
                                                 alt="Zerstörter Eingangsbereich im Hauptgebäude der LMU, 1945"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_2">
                                    <p>
                                        „Angesichts des Trümmerfeldes …“, so lauten die ersten Worte der Verfassung
                                        Bayerns. Wie ganz München war auch das Hauptgebäude der
                                        Ludwig-Maximilians-Universität von Kriegszerstörungen betroffen. Der Gebäudeteil
                                        der Großen Aula war weniger schwer beschädigt und konnte so schon ab Februar
                                        1946 wieder genutzt werden. Die 700 Personen fassende Große Aula diente nicht
                                        nur als Raum für die Volksvertreter, sondern auch als Konzertsaal für die
                                        Philharmoniker und für universitäre Veranstaltungen.
                                    </p>
                                    <p className="copy">Abb. Bayerisches Landesamt für Denkmalpflege, Nr. 01009202</p>
                                </div>
                                <figcaption>
                                    Zerstörter Eingangsbereich im Hauptgebäude der LMU, 1945
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Muenchen-LMU/lmu_bild_0007.jpg"}
                                   data-glightbox="title: Erste Landtagssitzung nach dem Krieg am 16. Dezember 1946 in der Großen Aula der Ludwig-Maximilians-Universität München; description: .custom-desc1_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0007.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Erste Landtagssitzung nach dem Krieg am 16. Dezember 1946 in der Großen Aula der Ludwig-Maximilians-Universität München"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p>
                                        Am 21. Dezember 1946 wählte der Landtag Hans Ehard (CSU) zum ersten demokratisch
                                        legitimierten Ministerpräsidenten nach Kriegsende.
                                    </p>
                                    <p className="copy">Abb. Bildarchiv Bayerischer Landtag</p>
                                </div>
                                <figcaption>
                                    Erste Landtagssitzung nach dem Krieg am 16. Dezember 1946 in der Großen Aula der
                                    Ludwig-Maximilians-Universität München
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
