import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Novel = (key) => {
    return (
        <div>
            <Container.Fullwidth key={key.toString() + 'slider'}>
                <Container.Novel id={key} sliderTitleId="nodelTitle">
                    <div className="bg-grey">
                        <p>1946 München – Große Aula der Ludwigs-Maximilian-Universität</p>
                        <h2>Im September 1946 in der Münchner Ludwig-Maximilians-Universität vor einer Sitzung der Verfassunggebenden Landesversammlung</h2>
                        <StaticImage src="../../../../static/images/Muenchen-LMU/Novel/Szene_1_Bild_1.png" placeholder="none"  alt=""/>
                    </div>
                    <StaticImage src="../../../../static/images/Muenchen-LMU/Novel/Szene_1_Bild_2.png" placeholder="none"  alt=""/>
                    <StaticImage src="../../../../static/images/Muenchen-LMU/Novel/Szene_1_Bild_3.png" placeholder="none"  alt=""/>
                    <StaticImage src="../../../../static/images/Muenchen-LMU/Novel/Szene_1_Bild_4.png" placeholder="none"  alt=""/>
                    <div className="bg-grey">
                        <h2>Erste Landtagssitzung nach dem Krieg, am 16. Dezember 1946 in der Großen Aula der LMU</h2>
                        <StaticImage src="../../../../static/images/Muenchen-LMU/Novel/Szene_2_Bild_1.png" placeholder="none"  alt=""/>
                    </div>
                </Container.Novel>
            </Container.Fullwidth>
        </div>
    )
}

export default Novel

