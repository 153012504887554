import * as React from 'react'
import * as Container from "../../container/container";
import {StaticImage} from "gatsby-plugin-image";

const TextBlock2White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-LMU/lmu_bild_0009.jpg"}
                           data-glightbox="title: Verfassungsentwurf des Vorbereitenden Verfassungsausschusses mit handschriftlichen Anmerkungen, 1946; description: .custom-desc1_3; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0009.jpg" width={335}
                                         layout="fixed" placeholder="none"
                                         alt="Verfassungsentwurf des Vorbereitenden Verfassungsausschusses mit handschriftlichen Anmerkungen, 1946"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_3">
                            <p>
                                Auf Basis von Wilhelm Hoegners Entwurf, der auf seine Überlegungen aus dem Schweizer
                                Exil zurückging, erarbeitete der Beratende Landesausschuss einen Vorentwurf für die
                                bayerische Verfassung. Es sind mehrere Arbeitsversionen erhalten. Die hier gezeigte
                                Entwurfsseite stammt aus dem Nachlass von Hans Ehard.
                            </p>
                            <p className="copy">Abb. Bayerisches Hauptstaatsarchiv, NL_Ehard_Hans_1629</p>
                        </div>
                        <figcaption>
                            Verfassungsentwurf des Vorbereitenden Verfassungsausschusses mit handschriftlichen
                            Anmerkungen, 1946
                            <span className="description">
                                Auf Basis von Wilhelm Hoegners Entwurf, der auf seine Überlegungen aus dem Schweizer
                                Exil zurückging, erarbeitete der Beratende Landesausschuss einen Vorentwurf für die
                                bayerische Verfassung. Es sind mehrere Arbeitsversionen erhalten. Die hier gezeigte
                                Entwurfsseite stammt aus dem Nachlass von Hans Ehard.</span>
                        </figcaption>
                    </figure>
                    <p>
                        <Container.Facsimile
                            pdf="/facsimile/09_03_Zusätzliches Dokument_Faksimile_LA02a_Leseversion-1.pdf"
                            img="/images/Verfassung_alt.png"
                            text="Verfassung des Freistaates Bayern vom 26. Oktober 1946"
                            alttext="Verfassung des Freistaats Bayern vom 26. Oktober 1946"/>
                    </p>
                    <p>
                        <Container.Facsimile
                            pdf="/facsimile/09_03_Achtung! Bitte nur Seiten mit der Verfassung.pdf"
                            img="/images/Verfassung_neu.png"
                            alttext="Verfassung des Freistaats Bayern"
                            text="Die aktuelle Verfassung des Freistaates Bayern"/>
                    </p>
                    <br/>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-LMU/lmu_bild_0010.jpg"}
                           data-glightbox="title: Stimmzettel für den Volksentscheid über die Bayerische Verfassung am 1. Dezember 1946. ; description: .custom-desc1_6; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0010.jpg" width={335}
                                         layout="fixed" placeholder="none"
                                         alt="Stimmzettel für den Volksentscheid über die Bayerische Verfassung am 1. Dezember 1946."/>
                        </a>
                        <div className="glightbox-desc custom-desc1_6">
                            <p>
                                Am Volksentscheid beteiligten sich 75,7 Prozent der über vier Millionen Wahlberechtigten
                                Bayerns. 70,6 Prozent stimmten dafür.
                            </p>
                            <p className="copy">Abb. Bildarchiv Bayerischer Landtag</p>
                        </div>
                        <figcaption>
                            Stimmzettel für den Volksentscheid über die Bayerische Verfassung am 1. Dezember 1946.
                            <span className="description">
                                Am Volksentscheid beteiligten sich 75,7 Prozent der über vier Millionen Wahlberechtigten
                                Bayerns. 70,6 Prozent stimmten dafür.</span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-LMU/lmu_bild_0005.jpg"}
                           data-glightbox="title: Wahl zur Verfassunggebenden Landesversammlung in München, Juni 1946; description: .custom-desc1_4; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0005.jpg" width={335}
                                         layout="fixed" placeholder="none"
                                         alt="Wahl zur Verfassunggebenden Landesversammlung in München, Juni 1946"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_4">
                            <p>
                                Aufgrund der Kriegszerstörung musste teils in improvisierten „Wahllokalen“ im Freien
                                gewählt werden. Die Wahlen hatten eine absolute Mehrheit der CSU mit 109 von 180
                                Mitgliedern in der Verfassunggebenden Landesversammlung zum Ergebnis. Die SPD bekam 51
                                Mandate, die KPD 9, die Wirtschaftliche Aufbau-Vereinigung (WAV) 8 und die FDP 3.
                            </p>
                            <p className="copy">Abb. Haus der Bayerischen Geschichte (Bayerisches Pressebild)</p>
                        </div>
                        <figcaption>
                            Wahl zur Verfassunggebenden Landesversammlung in München, Juni 1946
                            <span className="description">
                                Aufgrund der Kriegszerstörung musste teils in improvisierten „Wahllokalen“ im Freien
                                gewählt werden. Die Wahlen hatten eine absolute Mehrheit der CSU mit 109 von 180
                                Mitgliedern in der Verfassunggebenden Landesversammlung zum Ergebnis. Die SPD bekam 51
                                Mandate, die KPD 9, die Wirtschaftliche Aufbau-Vereinigung (WAV) 8 und die FDP 3.</span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-LMU/lmu_bild_0006.jpg"}
                           data-glightbox="title: Wilhelm Hoegner (SPD) und Alois Hundhammer (CSU), um 1950; description: .custom-desc1_5; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Muenchen-LMU/lmu_bild_0006.jpg" width={335}
                                         layout="fixed" placeholder="none"
                                         alt="Wilhelm Hoegner (SPD) und Alois Hundhammer (CSU), um 1950"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_5">
                            <p>
                                Die prägenden Persönlichkeiten in der Verfassunggebenden Landesversammlung waren
                                Ministerpräsident Wilhelm Hoegner und Alois Hundhammer, der als Fraktionsvorsitzender
                                der CSU eine große Mehrheit hinter sich hatte. Die Kompromissbereitschaft zwischen CSU
                                und SPD war entscheidend für die Arbeit an der Verfassung.
                            </p>
                            <p className="copy">Abb. Alfred Strobel / Süddeutsche Zeitung Photo</p>
                        </div>
                        <figcaption>
                            Wilhelm Hoegner (SPD) und Alois Hundhammer (CSU), um 1950
                            <span className="description">
                                Die prägenden Persönlichkeiten in der Verfassunggebenden Landesversammlung waren
                                Ministerpräsident Wilhelm Hoegner und Alois Hundhammer, der als Fraktionsvorsitzender
                                der CSU eine große Mehrheit hinter sich hatte. Die Kompromissbereitschaft zwischen CSU
                                und SPD war entscheidend für die Arbeit an der Verfassung.</span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2White
