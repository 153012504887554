import * as React from 'react'
import * as Container from "../../container/container";

const Video = (key) => {
    return (
        <Container.Part background="beige" id="trailer" key={key}>
            <Container.Row>
                <div className="col-12">
                    <div className="inner-container">
                        <h2>Präsident Bernd Huber spricht über demokratische Ereignisse in der
                            Ludwig-Maximilians-Universität.</h2>
                        <figure className="video">
                            <div className="yt-container yt-enabled">
                                <Container.Video
                                    url={"/videos/Filmmaterial/009_LMU/211011_LMU.mp4"}
                                    poster={"/images/Videowallpaper/lmu.jpg"}/>
                            </div>
                        </figure>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default Video
