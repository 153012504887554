import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock3 = (key) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Verfassungsänderungen</h2>
                    <p>
                        Nur durch Volksentscheid kann die Bayerische Verfassung geändert werden. Von 1946 bis 2020
                        gab es 16 Änderungen. Zwei davon wurden durch ein Volksbegehren und 14 durch den Landtag
                        initiiert. Stößt der Landtag eine Änderung an, wird der Bevölkerung ein Gesetzentwurf zum
                        Volksentscheid vorgelegt. Wenn die Initiative von der Bevölkerung ausgeht, muss zuerst ein
                        mit 25.000 Unterschriften unterstützter Antrag auf ein Volksbegehren gestellt und ein
                        Gesetzentwurf vorgelegt werden. Bekommt das Volksbegehren die Unterstützung von zehn Prozent
                        der Stimmberechtigten, geht es zur Stellungnahme an die Staatsregierung. Danach entscheidet
                        der Landtag, ob er den Entwurf als Gesetz umsetzt oder ablehnt. Bei einer Ablehnung kommt es
                        zum Volksentscheid durch die Bevölkerung.
                    </p>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3
